import { Link } from "gatsby"
import * as React from "react"
import { links } from "../common/constants"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />

    <section className="py-10 md:py-14 lg:py-24">
      <div className="px-4 mx-auto">
        <div className="max-w-4xl mx-auto">
          <div className="px-4 mb-12 lg:mb-0 text-justify">
            <h1 className="text-4xl lg:text-5xl text-center font-heading mb-10">
              Politica sulla riservatezza
              <span className="block text-base text-blue-600">
                In vigore dal 20 luglio 2022
              </span>
            </h1>
            <p className=" mb-5">
              Montebello Group S.r.l.s ("noi", "noi" o "nostro") gestisce il
              sito web
              <Link
                to={links.home}
                className="text-blue-600 hover:text-blue-500"
              >
                https://www.gruppomontebello.it
              </Link>{" "}
              (di seguito denominato il "Servizio").
            </p>
            <p className=" mb-5">
              Questa pagina ti informa delle nostre politiche relative alla
              raccolta, all'uso e alla divulgazione dei dati personali quando
              utilizzi il nostro Servizio e le scelte che hai associato a tali
              dati.
            </p>
            <p className=" mb-5">
              Utilizziamo i tuoi dati per fornire e migliorare il Servizio.
              Utilizzando il Servizio, accetti la raccolta e l'utilizzo delle
              informazioni in conformità con questa politica. Salvo quanto
              diversamente definito nella presente Privacy Policy, i termini
              utilizzati nella presente Privacy Policy hanno lo stesso
              significato dei nostri Termini e Condizioni, accessibili da
              <Link
                to={links.home}
                className="text-blue-600 hover:text-blue-500"
              >
                https://www.gruppomontebello.it
              </Link>
              .
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Raccolta e utilizzo delle informazioni
            </h2>
            <p className=" mb-5">
              Raccogliamo diversi tipi di informazioni per vari scopi per
              fornire e migliorare il nostro Servizio.
            </p>

            <h3 className="text-2xl lg:text-3xl font-heading my-4">
              Tipi di dati raccolti
            </h3>
            <h4 className="text-xl lg:text-2xl font-heading my-3">
              Dati personali
            </h4>
            <p className=" mb-2">
              Durante l'utilizzo del nostro Servizio, potremmo chiederti di
              fornirci alcune informazioni di identificazione personale che
              possono essere utilizzate per contattarti o identificarti ("Dati
              personali"). Le informazioni di identificazione personale possono
              includere, ma non sono limitate a:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>Cookie e dati di utilizzo</li>
            </ul>

            <h4 className="text-xl lg:text-2xl font-heading my-3">
              Dati di utilizzo
            </h4>
            <p className=" mb-5">
              Potremmo anche raccogliere informazioni su come si accede e si
              utilizza il Servizio ("Dati di utilizzo"). Questi Dati di utilizzo
              possono includere informazioni come l'indirizzo del protocollo
              Internet del tuo computer (ad es. indirizzo IP), il tipo di
              browser, la versione del browser, le pagine del nostro Servizio
              che visiti, l'ora e la data della tua visita, il tempo trascorso
              su quelle pagine, univoco identificatori del dispositivo e altri
              dati diagnostici.
            </p>

            <h4 className="text-xl lg:text-2xl font-heading my-3">
              Dati di tracciamento e cookie
            </h4>
            <p className=" mb-5">
              Utilizziamo cookie e tecnologie di tracciamento simili per
              tracciare l'attività sul nostro Servizio e conservare determinate
              informazioni.
            </p>
            <p className=" mb-5">
              I cookie sono file con una piccola quantità di dati che possono
              includere un identificatore univoco anonimo. I cookie vengono
              inviati al tuo browser da un sito Web e memorizzati sul tuo
              dispositivo. Le tecnologie di tracciamento utilizzate sono anche
              beacon, tag e script per raccogliere e tenere traccia delle
              informazioni e per migliorare e analizzare il nostro Servizio.
            </p>
            <p className=" mb-5">
              Puoi indicare al tuo browser di rifiutare tutti i cookie o di
              indicare quando un cookie viene inviato. Tuttavia, se non accetti
              i cookie, potresti non essere in grado di utilizzare alcune parti
              del nostro Servizio. Puoi saperne di più su come gestire i cookie
              nella Guida ai cookie del browser.
            </p>
            <p className=" mb-2">Esempi di cookie che utilizziamo:</p>
            <ul className="list-disc list-inside mb-5">
              <li>
                <strong>Cookie di sessione.</strong> Utilizziamo i cookie di
                sessione per gestire il nostro servizio.
              </li>
              <li>
                <strong>Cookie di sicurezza.</strong> Utilizziamo i cookie di
                sicurezza per scopi di sicurezza.
              </li>
              <li>
                <strong>Cookie analitici.</strong> Utilizziamo i cookie
                analitici per scopi analitici.
              </li>
              <li>
                <strong>Cookie di preferenza.</strong> Utilizziamo i cookie
                delle preferenze per ricordare le tue preferenze e varie
                impostazioni.
              </li>
            </ul>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Utilizzo dei dati
            </h2>
            <p className=" mb-2">
              Montebello Group S.r.l.s utilizza i dati raccolti per diverse
              finalità:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>Per fornire e mantenere il Servizio</li>
              <li>Per informarti sulle modifiche al nostro Servizio</li>
              <li>
                Per consentirti di partecipare alle funzionalità interattive del
                nostro Servizio quando scegli di farlo
              </li>
              <li>Per fornire assistenza e supporto ai clienti</li>
              <li>
                Per fornire analisi o informazioni preziose in modo da poter
                migliorare il Servizio
              </li>
              <li>Per monitorare l'utilizzo del Servizio</li>
              <li>Per rilevare, prevenire e risolvere problemi tecnici</li>
            </ul>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Trasferimento di dati
            </h2>
            <p className=" mb-5">
              Le tue informazioni, inclusi i Dati personali, possono essere
              trasferite e mantenute su computer situati al di fuori del tuo
              stato, provincia, paese o altra giurisdizione governativa in cui
              le leggi sulla protezione dei dati potrebbero differire da quelle
              della tua giurisdizione.
            </p>
            <p className=" mb-5">
              Se scegli di fornirci informazioni, tieni presente che potremmo
              trasferire i dati, inclusi i Dati personali, a computer situati
              negli Emirati Arabi Uniti o nell'UE ed elaborarli lì.
            </p>
            <p className=" mb-5">
              Il tuo consenso alla presente Informativa sulla privacy seguito
              dall'invio di tali informazioni rappresenta il tuo consenso a tale
              trasferimento.
            </p>
            <p className=" mb-5">
              Montebello Group S.r.l.s adotterà tutte le misure ragionevolmente
              necessarie per garantire che i tuoi dati siano trattati in modo
              sicuro e in conformità con la presente Informativa sulla privacy e
              nessun trasferimento dei tuoi Dati Personali avverrà a
              un'organizzazione o un paese a meno che non siano in atto
              controlli adeguati, inclusa la sicurezza dei tuoi dati e altre
              informazioni personali.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Divulgazione dei dati
            </h2>
            <h3 className="text-2xl lg:text-3xl font-heading my-3">
              Requisiti legali
            </h3>
            <p className=" mb-2">
              Montebello Group S.r.l.s potrà divulgare i tuoi Dati Personali in
              buona fede ritenendo che tale azione sia necessaria per:
            </p>
            <ul className="list-disc list-inside mb-5">
              <li>Per adempiere ad un obbligo di legge</li>
              <li>
                Proteggere e difendere i diritti o la proprietà di Montebello
                Group S.r.l.s.
              </li>
              <li>
                Per prevenire o indagare su possibili illeciti in relazione al
                Servizio
              </li>
              <li>
                Per proteggere la sicurezza personale degl'utenti del Servizio o
                persone fisiche
              </li>
              <li>Per tutelarsi dalla responsabilità legale</li>
            </ul>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Sicurezza dei dati
            </h2>
            <p className=" mb-5">
              La sicurezza dei tuoi dati è importante per noi, ma ricorda che
              nessun metodo di trasmissione su Internet o metodo di
              archiviazione elettronica è sicuro al 100%. Sebbene ci sforziamo
              di utilizzare mezzi commercialmente accettabili per proteggere i
              tuoi dati personali, non possiamo garantirne la sicurezza
              assoluta.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Fornitori di servizi
            </h2>
            <p className=" mb-5">
              Possiamo impiegare società e individui di terze parti per
              facilitare il nostro Servizio ("Fornitori di servizi"), per
              fornire il Servizio per nostro conto, per eseguire servizi
              relativi al Servizio o per aiutarci ad analizzare come viene
              utilizzato il nostro Servizio.
            </p>
            <p className=" mb-5">
              Queste terze parti hanno accesso ai tuoi Dati Personali solo per
              svolgere queste attività per nostro conto e sono obbligate a non
              divulgarli o utilizzarli per nessun altro scopo.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Collegamenti ad altri siti
            </h2>
            <p className=" mb-5">
              Il nostro Servizio può contenere collegamenti ad altri siti che
              non sono gestiti da noi. Se fai clic su un collegamento di terze
              parti, verrai indirizzato al sito di tale terza parte. Ti
              consigliamo vivamente di rivedere la Privacy Policy di ogni sito
              che visiti.
            </p>
            <p className=" mb-5">
              Non abbiamo alcun controllo e non ci assumiamo alcuna
              responsabilità per il contenuto, le politiche sulla privacy o le
              pratiche di siti o servizi di terze parti.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Privacy dei bambini
            </h2>
            <p className=" mb-5">
              Il nostro Servizio non si rivolge a persone di età inferiore ai 18
              anni ("Bambini").
            </p>
            <p className=" mb-5">
              Non raccogliamo consapevolmente informazioni di identificazione
              personale da persone di età inferiore ai 18 anni. Se sei un
              genitore o tutore e sei consapevole che i tuoi figli ci hanno
              fornito Dati personali, ti preghiamo di contattarci. Se veniamo a
              conoscenza di aver raccolto Dati personali da bambini senza la
              verifica del consenso dei genitori, adottiamo misure per rimuovere
              tali informazioni dai nostri server.
            </p>

            <h2 className="text-3xl lg:text-4xl font-heading my-6">
              Modifiche alla presente Informativa sulla privacy
            </h2>
            <p className=" mb-5">
              Potremmo aggiornare la nostra Informativa sulla privacy di volta
              in volta. Ti avviseremo di eventuali modifiche pubblicando la
              nuova Privacy Policy in questa pagina.
            </p>
            <p className=" mb-5">
              Si consiglia di rivedere periodicamente la presente Informativa
              sulla privacy per eventuali modifiche. Le modifiche alla presente
              Informativa sulla privacy sono efficaci quando vengono pubblicate
              su questa pagina.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPolicyPage
